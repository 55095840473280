import {
  UserLongestMonologueResponseModel,
  UserSpeakingRateResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { SentimentScore, SentimentSpeaker } from '@capturi/api-sentiment'
import { useAPI } from '@capturi/api-utils'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useFirstPhoneSegmentState } from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import { useOrganization } from '@capturi/stores'
import { NumberCard } from '@capturi/ui-components'
import {
  Box,
  BoxProps,
  Flex,
  Grid,
  GridItem,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, select, t } from '@lingui/macro'
import { SentimentTooltipTrigger } from 'components/SentimentTooltip'
import NoAccessibleTrackers from 'pages/analytics/shared/components/NoAccessibleTrackers'
import React from 'react'
import formatSeconds from 'utils/formatSeconds'

import { coachingAPI } from './api'
import { integerFormat, percentFormat } from './numberFormat'
import { CoachingStatisticsResponseModel } from './types'

type Props = {
  userUid: string
}

const Interaction: React.FC<Props> = ({ userUid }) => {
  const { enableSentimentFeature } = useFeatureFlags()
  const segmentState = useFirstPhoneSegmentState()

  const { data, error: coachingStatsError } =
    useAPI<CoachingStatisticsResponseModel>(
      coachingAPI.getCoachingStats(userUid),
      segmentState.getFilterRequestModel,
      {
        suspense: false,
        revalidateOnFocus: false,
      },
    )

  const { data: speakingRate, error: speakingRateError } =
    useAPI<UserSpeakingRateResponseModel>(
      insightsAPI.getSpeakingRateStatsForUser(userUid),
      segmentState.getFilterRequestModel,
      {
        suspense: false,
      },
    )

  const { data: longestMonologue, error: longestMonologueError } =
    useAPI<UserLongestMonologueResponseModel>(
      insightsAPI.getLongestMonologueStatsForUser(userUid),
      segmentState.getFilterRequestModel,
      {
        suspense: false,
      },
    )

  const { speakDurationPercent = 0 } = data?.durationStatistics ?? {}

  const averageLongestMonologue =
    longestMonologue?.user?.salesPerson?.averageLongestMonologue || 0

  const averageWPM = Math.round(
    speakingRate?.user?.salesPerson?.averageWordsPerMinute || 0,
  )

  const {
    otherHighScoreCount = 0,
    otherMediumScoreCount = 0,
    otherLowScoreCount = 0,
  } = data?.sentimentStatistics ?? {}

  if (
    coachingStatsError?.statusCode === 403 ||
    speakingRateError?.statusCode === 403 ||
    longestMonologueError?.statusCode === 403
  ) {
    return <NoAccessibleTrackers />
  }

  const generalError =
    coachingStatsError || speakingRateError || longestMonologueError

  if (generalError) return <DefaultFallbackComponent error={generalError} />

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: 'repeat(3, 1fr)',
      }}
      gap={4}
    >
      <GridItem>
        <NumberCard
          label={<Trans>Talk ratio</Trans>}
          value={i18n.number(speakDurationPercent / 100, percentFormat)}
          h="full"
        />
      </GridItem>
      <GridItem>
        <NumberCard
          label={<Trans>Speech rate</Trans>}
          value={t`${i18n.number(averageWPM, integerFormat)} wpm`}
          h="full"
        />
      </GridItem>
      <GridItem>
        <NumberCard
          label={<Trans>Longest monologue</Trans>}
          value={formatSeconds(averageLongestMonologue)}
          h="full"
        />
      </GridItem>
      {enableSentimentFeature && (
        <GridItem>
          <SentimentCard
            speaker="Other"
            scores={{
              High: otherHighScoreCount,
              Medium: otherMediumScoreCount,
              Low: otherLowScoreCount,
            }}
            h="full"
          />
        </GridItem>
      )}
    </Grid>
  )
}

const SentimentCard: React.FC<
  {
    speaker: SentimentSpeaker
    scores: { [key in SentimentScore]: number }
  } & BoxProps
> = ({ speaker, scores, ...props }) => {
  const { organizationType } = useOrganization()
  const speakerName =
    speaker === 'User'
      ? t`employee`
      : select(organizationType, {
          public: 'Citizen',
          other: 'Customer',
        })

  const tooltipText = (score: SentimentScore): string => {
    const scoreCount = scores[score]
    const scoreName = select(score, {
      Low: 'low',
      Medium: 'medium',
      High: 'high',
      other: 'unknown',
    })
    return t`${scoreCount} conversations with a ${scoreName} sentiment score`
  }
  return (
    <NumberCard
      label={
        <Box pr={5} pos="relative">
          <Trans>
            Sentiment score, <strong>{speakerName}</strong>
          </Trans>
          <SentimentTooltipTrigger pos="absolute" top={0} right={0} />
        </Box>
      }
      {...props}
    >
      <Grid templateColumns="repeat(3, 1fr)" gap={2}>
        <Tooltip label={tooltipText('High')}>
          <Flex direction="column">
            <Text>{scores.High}</Text>
            <Text fontSize="sm" color="textMuted" fontWeight="normal">
              <Trans>High</Trans>
            </Text>
          </Flex>
        </Tooltip>
        <Tooltip label={tooltipText('Medium')}>
          <Flex direction="column">
            <Text>{scores.Medium}</Text>
            <Text fontSize="sm" color="textMuted" fontWeight="normal">
              <Trans>Medium</Trans>
            </Text>
          </Flex>
        </Tooltip>
        <Tooltip label={tooltipText('Low')}>
          <Flex direction="column">
            <Text>{scores.Low}</Text>
            <Text fontSize="sm" color="textMuted" fontWeight="normal">
              <Trans>Low</Trans>
            </Text>
          </Flex>
        </Tooltip>
      </Grid>
    </NumberCard>
  )
}

export default Interaction
