import { useAPI } from '@capturi/api-utils'
import { useMemo } from 'react'
import { SWRConfiguration, SWRResponse } from 'swr'

import { coachingAPI } from './api'
import { CoachingComment, CoachingCommentsResponseModel } from './types'

function useCoachingComments(
  userUid: string,
  swrConfig: SWRConfiguration = {},
): {
  comments: CoachingComment[]
  isLoading: boolean
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  mutate: SWRResponse<CoachingCommentsResponseModel, any>['mutate']
} {
  const { data, isValidating, mutate } = useAPI<CoachingCommentsResponseModel>(
    coachingAPI.getComments(userUid),
    () => ({
      pageSize: 999,
      pageNumber: 0,
      sortDirection: 1,
    }),
    {
      revalidateOnFocus: false,
      dedupingInterval: 10000,
      ...swrConfig,
    },
  )
  return useMemo(() => {
    return {
      comments: (data?.coachingComments ?? []).sort(
        (a, b) => b.createdOn.getTime() - a.createdOn.getTime(),
      ),
      isLoading: isValidating,
      mutate,
    }
  }, [data, isValidating, mutate])
}

export default useCoachingComments
