import request from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import { t } from '@lingui/macro'
import React from 'react'

import { CoachingCommentsAnalyticsEvent, logEvent } from './analytics'
import { coachingAPI } from './api'
import { CreateCoachingCommentDialog } from './components/CreateCoachingCommentDialog'
import { EditCoachingCommentDialog } from './components/EditCoachingCommentDialog'
import { CoachingComment } from './types'

type UseCoachingCommentCRUDOptions = {
  onCommentCreated?: (comment: CoachingComment) => void
  onCommentUpdated?: (comment: CoachingComment) => void
  onCommentDeleted?: (comment: CoachingComment) => void
}

type UseCoachingCommentCRUD = {
  createComment: (userUid: string) => void
  editComment: (comment: CoachingComment) => void
  deleteComment: (comment: CoachingComment) => void
}

function useCoachingCommentCRUD(
  options: UseCoachingCommentCRUDOptions = {},
): UseCoachingCommentCRUD {
  const toast = useToast()
  const confirm = useConfirm()

  const [openCreateCommentDialog] = useModal(CreateCoachingCommentDialog)
  const [openEditCommentDialog] = useModal(EditCoachingCommentDialog)

  const { onCommentCreated, onCommentUpdated, onCommentDeleted } = options

  const handleCreateComment = React.useCallback(
    async (userUid: string, text: string) => {
      try {
        const newComment: CoachingComment = await request(
          coachingAPI.createComment(userUid, text),
        )
        onCommentCreated?.(newComment)
        toast({
          title: t`Goal created`,
          description: t`The goal has been created`,
          status: 'success',
        })
      } catch (_error) {
        toast({
          title: t`An error occurred`,
          description: t`The goal was not created`,
          status: 'error',
        })
      }
    },
    [toast, onCommentCreated],
  )

  const handleUpdateComment = React.useCallback(
    async (comment: CoachingComment) => {
      try {
        const updatedComment: CoachingComment = await request(
          coachingAPI.updateComment(comment.uid, comment.userUid, comment.text),
        )
        onCommentUpdated?.(updatedComment)
        toast({
          title: t`Goal updated`,
          description: t`The goal has been updated`,
          status: 'success',
        })
      } catch (_error) {
        toast({
          title: t`An error occurred`,
          description: t`The goal was not updated`,
          status: 'error',
        })
      }
    },
    [toast, onCommentUpdated],
  )

  const handleDeleteComment = React.useCallback(
    async (comment: CoachingComment) => {
      try {
        await request(coachingAPI.deleteComment(comment.uid, comment.userUid))
        onCommentDeleted?.(comment)
        toast({
          title: t`Goal deleted`,
          description: t`The goal has been deleted`,
          status: 'success',
        })
      } catch (_error) {
        toast({
          title: t`An error occurred`,
          description: t`The goal was not deleted`,
          status: 'error',
        })
      }
    },
    [toast, onCommentDeleted],
  )

  return React.useMemo(() => {
    return {
      createComment: (userUid: string) => {
        openCreateCommentDialog({
          onSubmit: ({ text }) => {
            logEvent(CoachingCommentsAnalyticsEvent.CreateComment)
            // Create comment
            handleCreateComment(userUid, text)
          },
        })
      },
      editComment: (comment: CoachingComment) => {
        openEditCommentDialog({
          comment,
          onSubmit: (comment) => {
            logEvent(CoachingCommentsAnalyticsEvent.UpdateComment)
            // Update comment
            handleUpdateComment(comment)
          },
        })
      },
      deleteComment: async (comment: CoachingComment) => {
        try {
          // Open confirm dialog
          await confirm({
            title: t`Delete goal`,
            description: t`Are you sure want to delete the goal?`,
            cancelText: t`Cancel`,
            confirmText: t`Delete`,
          })
          logEvent(CoachingCommentsAnalyticsEvent.DeleteComment)
          // Delete comment
          handleDeleteComment(comment)
        } catch {
          // cancelled
        }
      },
    }
  }, [
    confirm,
    openCreateCommentDialog,
    openEditCommentDialog,
    handleCreateComment,
    handleUpdateComment,
    handleDeleteComment,
  ])
}

export default useCoachingCommentCRUD
