import {
  Box,
  BoxProps,
  Heading,
  Icon,
  IconProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdInfoOutline } from 'react-icons/md'

const SentimentTooltipContent: React.FC = () => {
  return (
    <Box>
      <Heading mb={4}>
        <Trans>Sentiment</Trans>
      </Heading>
      <Stack spacing={4}>
        <Box>
          <Heading size="md" fontWeight="medium">
            <Trans>Method</Trans>
          </Heading>
          <Stack spacing={4}>
            <Text>
              <Trans>
                Sentiment can give an indication of the mood of a conversation.
                The sentiment score in Capturi is based on the language and
                words used in the conversation.
              </Trans>
            </Text>
            <Text>
              <Trans>
                All words in the conversation are weighted with either a
                positive, negative or neutral score. These are added together
                into an overall score, which determines which sentiment category
                the conversation is placed in.
              </Trans>
            </Text>
            <Text>
              <Trans>This is done for each speaker separately.</Trans>
            </Text>
          </Stack>
        </Box>
        <Box>
          <Heading size="md" fontWeight="medium">
            <Trans>Examples of words and their weight</Trans>
          </Heading>
          <Stack spacing={4}>
            <Text>
              <Trans>
                Fantastic (positive), wonderful (positive), great help
                (positive), thank you (positive), frustrating (negative),
                hopeless (negative), complaint (negative), confused (negative)
              </Trans>
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}

const SentimentTooltip: React.FC<
  Omit<PopoverProps, 'children'> & { children?: React.ReactNode }
> = ({ children }) => {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <SentimentTooltipContent />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const SentimentTooltipTrigger: React.FC<
  { iconSize?: IconProps['boxSize'] } & BoxProps
> = ({ iconSize = 4, ...props }) => {
  return (
    <SentimentTooltip>
      <Box
        lineHeight={0}
        cursor="pinter"
        _hover={{ color: 'primary.500' }}
        {...props}
      >
        <Icon as={MdInfoOutline} boxSize={iconSize} />
      </Box>
    </SentimentTooltip>
  )
}

export default SentimentTooltip
