import { BoxProps } from '@chakra-ui/react'
import React, { ReactElement, ReactNode } from 'react'

import { Card, CardContent, CardDescription, CardMeta } from '../Card'

type Props = {
  label: string | ReactElement
  value?: number | string | ReactElement
  children?: ReactNode
} & BoxProps

const NumberCard: React.FC<Props> = ({
  label,
  value,
  children,
  ...restProps
}) => {
  return (
    <Card {...restProps}>
      <CardContent
        h="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <CardMeta color="gray.800" fontWeight="medium" fontSize="lg" as="h3">
          {label}
        </CardMeta>
        <CardDescription
          as="div"
          fontSize="3xl"
          fontWeight="medium"
          lineHeight={1}
        >
          {children ?? value}
        </CardDescription>
      </CardContent>
    </Card>
  )
}

export default NumberCard
