import analytics from '@capturi/analytics'
import { useScore, useScores } from '@capturi/api-scoring'
import { SentimentScore } from '@capturi/api-sentiment'
import { useTrackers } from '@capturi/api-trackers'
import { useAPI } from '@capturi/api-utils'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import {
  ScoreParameterBreakdown,
  formatGoalRange,
  isWithinGoalRange,
} from '@capturi/scoring'
import { useOrganization } from '@capturi/stores'
import {
  Card,
  CardContent,
  CardHeader,
  CardMeta,
  Description,
  Highlight,
} from '@capturi/ui-components'
import {
  Box,
  BoxProps,
  Flex,
  Grid,
  GridItem,
  Icon,
  Progress,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  chakra,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, select, t } from '@lingui/macro'
import { ButtonLink } from 'components/Link'
import { SentimentTooltipTrigger } from 'components/SentimentTooltip'
import { routes as scoringRoutes } from 'pages/ScoringPage/routes'
import NoAccessibleTrackers from 'pages/analytics/shared/components/NoAccessibleTrackers'
import qs from 'query-string'
import React from 'react'
import { MdFlag, MdLaunch } from 'react-icons/md'
import { useMeasure } from 'react-use'
import formatSeconds from 'utils/formatSeconds'

import TrackerHits from './TrackerHits'
import { coachingAPI } from './api'
import { integerFormat } from './numberFormat'
import {
  CoachingStatisticsResponseModel,
  GetCoachingScoresResponseModel,
} from './types'

const valueTextShadow = '0px 4px 10px #42424280'

type Props = {
  userUid: string
}

type MergedScores = {
  uid: string
  averageScore: number
  maxPossibleScore: number
  conversationsTotal: number
  scoreName: string
  parameters: {
    parameterUid: string
    conversations: number
    conversationsPercent: number
  }[]
}

const Overview: React.FC<Props> = ({ userUid }) => {
  const { enableSentimentFeature } = useFeatureFlags()
  const segmentState = useFirstPhoneSegmentState()
  const { uiLanguage } = useOrganization()

  const { data: coachingStats, error: coachingStatsError } =
    useAPI<CoachingStatisticsResponseModel>(
      coachingAPI.getCoachingStats(userUid),
      segmentState.getFilterRequestModel,
      {
        suspense: false,
        revalidateOnFocus: false,
      },
    )

  const { data: trackers } = useTrackers()
  const { data: scores } = useScores()

  const isLoading = !(coachingStats || coachingStatsError)

  const { data: coachingScores, error: scoreError } =
    useAPI<GetCoachingScoresResponseModel>(
      coachingAPI.getScores(userUid),
      segmentState.getFilterRequestModel,
      { suspense: false },
    )
  const mergedScores = coachingScores?.scores.reduce<MergedScores[]>(
    (memo, coachingScore) => {
      const currentItem = scores?.find((item) => item.uid === coachingScore.uid)
      if (currentItem) {
        memo.push({
          ...coachingScore,
          scoreName: currentItem.name,
        })
      }
      return memo
    },
    [],
  )
  const { conversations = 0, durationAvg = 0 } =
    coachingStats?.durationStatistics ?? {}

  const {
    userHighScoreCount = 0,
    userMediumScoreCount = 0,
    userLowScoreCount = 0,
  } = coachingStats?.sentimentStatistics ?? {}

  if (
    coachingStatsError?.statusCode === 403 ||
    scoreError?.statusCode === 403
  ) {
    return <NoAccessibleTrackers />
  }

  const generalError = coachingStatsError || scoreError

  if (generalError) return <DefaultFallbackComponent error={generalError} />

  return (
    <Stack spacing={4} mt={4}>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(4, 1fr)',
          xl: 'repeat(6, 1fr)',
        }}
        gap={4}
      >
        <GridItem colSpan={{ base: 1, xl: 2 }}>
          <MetricCard
            label={t`Conversations`}
            score={i18n.number(conversations, integerFormat)}
          />
        </GridItem>
        <GridItem colSpan={{ base: 1, xl: 2 }}>
          <MetricCard label={t`Duration`} score={formatSeconds(durationAvg)} />
        </GridItem>
        {enableSentimentFeature && (
          <GridItem colSpan={{ base: 2, xl: 2 }}>
            <SentimentDisplay
              speaker="User"
              maxScore={conversations}
              scores={{
                High: userHighScoreCount,
                Medium: userMediumScoreCount,
                Low: userLowScoreCount,
              }}
              h="full"
            />
          </GridItem>
        )}
      </Grid>
      <SimpleGrid minChildWidth="22em" spacing={4}>
        {mergedScores
          ?.sort((a, b) => a.scoreName.localeCompare(b.scoreName, uiLanguage))
          ?.map((score) => (
            <GridItem key={score.uid}>
              <React.Suspense fallback={<Skeleton h="15em" w="100%" />}>
                <ScoreCard data={score} userUid={userUid} />
              </React.Suspense>
            </GridItem>
          ))}
      </SimpleGrid>
      <Card>
        <CardContent>
          <CardHeader>
            <Trans>Detected trackers</Trans>
          </CardHeader>
          <CardMeta>
            <Trans>
              Percentage of conversations where the trackers are detected
            </Trans>
          </CardMeta>
          <Box mt={2}>
            {trackers && (
              <TrackerHits
                trackerStats={coachingStats?.trackerStatistics}
                trackers={trackers}
                userUid={userUid}
                conversationCount={conversations}
                isLoading={isLoading}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Stack>
  )
}

const MetricCard: React.FC<
  {
    label: string
    score: number | string
  } & BoxProps
> = ({ label, score }) => {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>()
  const maxFontSize = 100
  const defualtFontSize = Math.min(width / 3.5, (height * 2) / 3)
  const fontSize = Math.min(defualtFontSize, maxFontSize)

  return (
    <Box ref={ref}>
      <Card pos="relative">
        <CardContent>
          <CardHeader>{label}</CardHeader>
          <Flex minH="150" alignItems="center" justify="center">
            <Text
              fontSize={fontSize}
              fontWeight="medium"
              display="inline"
              textShadow={valueTextShadow}
            >
              {score}
            </Text>
          </Flex>
        </CardContent>
      </Card>
    </Box>
  )
}

const SentimentDisplay: React.FC<
  {
    speaker: string
    maxScore: number
    scores: { [key in SentimentScore]: number }
  } & BoxProps
> = ({ speaker, scores, maxScore }) => {
  const { organizationType } = useOrganization()
  const scoreEntries = Object.entries(scores)
  const speakerName =
    speaker === 'User'
      ? t`Employee`
      : select(organizationType, {
          public: 'Citizen',
          other: 'Customer',
        })

  const scoreName = (label: string): string =>
    select(label, {
      Low: 'Low',
      Medium: 'Medium',
      High: 'High',
      other: label,
    })

  return (
    <Card h="100%">
      <CardContent>
        <CardHeader>
          <Trans>Sentiment score, {speakerName}</Trans>
          <SentimentTooltipTrigger ml="2" display="inline" />
        </CardHeader>

        <Grid gap={2} mt="4">
          {scoreEntries.map((item, index) => {
            const [label, itemScore] = item
            const percentage = Math.floor((itemScore / maxScore) * 100)

            return (
              <GridItem key={index}>
                <Flex justify="space-between" alignItems="center">
                  <Text fontSize="sm" fontWeight="medium">
                    {scoreName(label)}
                  </Text>
                  <Box>
                    {Number.isNaN(percentage) ? 0 : percentage}%
                    <Text as="span" ml="2" color="textMuted">
                      {itemScore} / {maxScore}
                    </Text>
                  </Box>
                </Flex>
                <Progress
                  colorScheme="segments.primary"
                  borderRadius="sm"
                  size="lg"
                  value={Number.isNaN(percentage) ? 0 : percentage}
                />
              </GridItem>
            )
          })}
        </Grid>
      </CardContent>
    </Card>
  )
}

const ScoreCard: React.FC<{
  data: MergedScores
  userUid: string
}> = ({ data, userUid }) => {
  const { periodDef } = useFilterPeriodContext()
  const { data: singleScoreData, error } = useScore(data.uid)

  const [ref, { width, height }] = useMeasure<HTMLDivElement>()
  const maxFontSize = 100
  const defualtFontSize = Math.min(width / 3.5, (height * 2) / 3)
  const fontSize = Math.min(defualtFontSize, maxFontSize)

  return (
    <Box ref={ref}>
      {error?.statusCode !== 403 && (
        <Card position="relative" h="100%">
          <CardContent>
            <ButtonLink
              aria-label={t`Go to score`}
              size="xs"
              to={qs.stringifyUrl({
                url: scoringRoutes.main(data?.uid),
                query: {
                  period: periodDef.name,
                  filter: JSON.stringify({
                    userUids: [userUid],
                  }),
                },
              })}
              onClick={() =>
                analytics.event('coaching__score-card__go-to-score')
              }
              variant="ghost"
              p={0}
              target="_blank"
              position="absolute"
              top={2}
              right={2}
            >
              <MdLaunch />
            </ButtonLink>
            <CardMeta color="gray.800" fontWeight="medium" fontSize="medium">
              <Trans>Score</Trans>, <Text as="span">{data?.scoreName}</Text>
            </CardMeta>
            <Flex align="center" justify="space-between" direction="column">
              <Flex align="flex-end" mt={2}>
                <Text
                  fontSize={fontSize}
                  fontWeight="medium"
                  textShadow={valueTextShadow}
                  lineHeight={1}
                >
                  {i18n.number(data.averageScore, integerFormat)}
                </Text>
                <Text
                  fontSize={fontSize * 0.7}
                  textShadow={valueTextShadow}
                  lineHeight={1}
                >
                  /{i18n.number(data.maxPossibleScore, integerFormat)}
                </Text>
              </Flex>
              {singleScoreData?.goal != null && (
                <Flex
                  align="center"
                  ml={4}
                  color={
                    isWithinGoalRange(data.averageScore, singleScoreData?.goal)
                      ? 'success'
                      : 'text'
                  }
                >
                  <Icon as={MdFlag} mr={2} />
                  <chakra.span>
                    <Trans>Goal</Trans>:
                  </chakra.span>
                  <chakra.span textTransform="lowercase" ml="0.35em">
                    {formatGoalRange(
                      singleScoreData.goal.min,
                      singleScoreData.goal.max,
                    )}
                  </chakra.span>
                </Flex>
              )}
            </Flex>
            <Box mt={4}>
              <Highlight>
                <Trans>Score breakdown</Trans>
              </Highlight>
              {singleScoreData?.description && (
                <Description fontSize="sm">
                  {singleScoreData.description}
                </Description>
              )}
            </Box>
            <Box mt={4}>
              <ScoreParameterBreakdown
                parameters={singleScoreData?.parameters ?? []}
                hits={data.parameters}
                conversationsTotal={data.conversationsTotal}
              />
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  )
}

export default Overview
