import analytics from '@capturi/analytics'

export enum CoachingCommentsAnalyticsEvent {
  // Comment CRUD
  CreateComment = 'coaching_createComment',
  UpdateComment = 'coaching_updateComment',
  DeleteComment = 'coaching_deleteComment',
  // Tracker conversation Drawer
  ViewTrackerConversations = 'coaching_ViewTrackerConversations',
}

export function logEvent(event: CoachingCommentsAnalyticsEvent): void {
  analytics.event(event)
}
