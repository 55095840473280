import { CoachingComment, CoachingCommentsResponseModel } from '../types'

type CacheUpdater = (
  comment: CoachingComment,
  cache?: CoachingCommentsResponseModel,
) => CoachingCommentsResponseModel

export const createComment: CacheUpdater = (comment, cache) => ({
  coachingComments: cache ? cache.coachingComments.concat(comment) : [comment],
})

export const updateComment: CacheUpdater = (comment, cache) => ({
  coachingComments: cache
    ? cache.coachingComments.map((x) => (x.uid === comment.uid ? comment : x))
    : [comment],
})

export const deleteComment: CacheUpdater = (comment, cache) => ({
  coachingComments: cache
    ? cache.coachingComments.filter((x) => x.uid !== comment.uid)
    : [],
})
