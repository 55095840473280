import { RequestOptions } from '@capturi/request'

export const DEFAULT_API_VERSION = '3.3'

const controller = 'coaching'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const coachingAPI = {
  getUserCoachingList: (): RequestOptions => ({
    url: 'authentication/coaching/users',
  }),
  getCoachingStats: (userUid: string): RequestOptions => ({
    url: `${controller}/${userUid}/statistics`,
    query: baseQuery,
    method: 'post',
  }),
  getScores: (userUid: string): RequestOptions => ({
    url: `${controller}/${userUid}/scores`,
    query: baseQuery,
    method: 'post',
  }),
  // Comments (goals)
  getComments: (userUid: string): RequestOptions => ({
    url: `${controller}/${userUid}/comments`,
    query: baseQuery,
  }),
  createComment: (userUid: string, text: string): RequestOptions => ({
    url: `${controller}/${userUid}/comments`,
    query: baseQuery,
    method: 'post',
    json: { text },
  }),
  updateComment: (
    uid: string,
    userUid: string,
    text: string,
  ): RequestOptions => ({
    url: `${controller}/${userUid}/comments/${uid}`,
    query: baseQuery,
    method: 'patch',
    json: { text },
  }),
  deleteComment: (uid: string, userUid: string): RequestOptions => ({
    url: `${controller}/${userUid}/comments/${uid}`,
    query: baseQuery,
    method: 'delete',
  }),
}
