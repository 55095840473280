import { useCurrentUser } from '@capturi/core'
import { useUsers } from '@capturi/stores'
import { PopoutSelect, SelectOption } from '@capturi/ui-select'
import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { MdArrowDropDown } from 'react-icons/md'

type UserSelectProps = {
  onSelectUser: (userId: string) => void
  currentSelectedUser: string
}

export const UserSelectPopover: React.FC<UserSelectProps> = ({
  currentSelectedUser,
  onSelectUser,
}) => {
  const { isTeamLead } = useCurrentUser()
  const { users } = useUsers()

  const filteredUsers = useMemo(
    () => users.filter((user) => (isTeamLead ? user.isSubordinate : true)),
    [isTeamLead, users],
  )
  const options = useMemo(
    () =>
      filteredUsers.map<SelectOption>((d) => ({
        value: d.uid,
        label: d.name,
      })),
    [filteredUsers],
  )
  return (
    <Popover isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant="ghost"
              px="8px"
              ml="-8px"
              height="fit-content"
              fontSize="2xl"
              rightIcon={<Icon as={MdArrowDropDown} boxSize="20px" />}
            >
              {currentSelectedUser}
            </Button>
          </PopoverTrigger>

          <PopoverContent>
            <PopoutSelect
              placeholder={t`Search`}
              options={options}
              noOptionsMessage={() => t`No options`}
              onChange={(user) => {
                if (user == null) return
                onSelectUser((user as SelectOption).value)
                onClose()
              }}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
