export const decimalFormat: Intl.NumberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}

export const percentFormat: Intl.NumberFormatOptions = {
  ...decimalFormat,
  style: 'percent',
}

export const integerFormat: Intl.NumberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}
